<template>
  <div class="dataM customerManagement">
    <div class="content">
      <div class="title">
        <div>客户列表</div>
        <div class="tool">
          <upload 
            ref="upload"
            templateName="客户管理导入模板"
            templateParam="customerInfo"
            @handleSearch="handleSearch"
            v-premiSub="'客户管理_导入'"
            />
          <el-tooltip v-premiSub="'客户管理_新建'" effect="dark" content="新增" placement="top">
            <img src="@/assets/images/dataManage/add.png" @click="handleCreate"/>
          </el-tooltip>
          <el-tooltip v-premiSub="'客户管理_删除'" effect="dark" content="删除" placement="top">
            <img src="@/assets/images/dataManage/batchdel.png" @click="comfirm('')"/>
          </el-tooltip>
          <el-input
            v-model="searchValue"
            style="width:220px"
            size="small"
            placeholder="请输入客户简称/编号"
            v-premiSub="'客户管理_搜索'"
            @keydown.enter.native="handleSearch(1)"
            >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch(1)"></i>
          </el-input>
        </div>
      </div>
        <el-table 
          :data="tableData" 
          header-cell-class-name="tableHeader" 
          style="width: 100%" 
          @selection-change="handleSelectionChange"
          @row-dblclick="handleEdit"
          >
          <el-table-column type="selection" width="55"/>
          <el-table-column label="序号" type="index" width="100">
            <template slot-scope="scope">
              <span>{{ ((page-1)*size+scope.$index+1) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="customerName" label="客户"/>
          <el-table-column prop="customerCode" label="客户编号"/>
          <el-table-column prop="priority" label="优先级"/>
          <el-table-column prop="colorNo" label="颜色">
            <template slot-scope="scope">
              <div class="colorBox" :style="{background:scope.row.colorNo}" :key="scope.row.id"></div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip v-premiSub="'客户管理_编辑'" effect="dark" content="编辑" placement="top">
                <img 
                  src="@/assets/images/dataManage/edit.png" 
                  style="margin-right:15px" 
                  @click="handleEdit(scope.row)"
                />
              </el-tooltip>
              <el-tooltip v-premiSub="'单个删除'" effect="dark" content="删除" placement="top">
                <img src="@/assets/images/dataManage/del.png"  @click="comfirm(scope.row)"/>
              </el-tooltip>
            </template>
          </el-table-column>
      </el-table>
      <pagination :total="total" :page.sync="page" :size.sync="size"  @getTableData="handleSearch"/>
    </div>
    <!-- 修改客户信息弹窗 -->
    <el-dialog
      :title="type==='create'?'新增':'编辑'"
      :visible.sync="dialogVisible"
      custom-class="dataM"
      width="690px"
      :close-on-click-modal="false"
      >
      <el-form :model="editData" :rules="rules" ref="ruleForm" class="editBox" :show-message="false">
        <el-form-item prop="customerName" class="item" label="客户">
          <el-input v-model.trim="editData.customerName"></el-input>
        </el-form-item>
        <el-form-item prop="customerCode" class="item" label="客户编号">
          <el-input v-model.trim="editData.customerCode"></el-input>
        </el-form-item>
        <el-form-item prop="priority" class="item" label="优先级">
          <el-select v-model="editData.priority">
            <el-option v-for="(item,index) in priorityList" :key="index" :label="item" :value="item"></el-option>
            <!-- <el-option label="2" :value="2"></el-option>
            <el-option label="3" :value="3"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item prop="colorNo" class="item" label="颜色">
          <!-- <el-color-picker v-model="editData.colorNo" size="small" :key="Math.random()"/> -->
          <el-popover
            placement="bottom"
            width="100%"
            trigger="click"
          >
            <div class="colorContent">
              <div class="item" 
              v-for="(item,index) in colorList" :key="index" 
              :style="{background:item}"
              @click="changeColor(item)"
            />
            </div>
            <div class="selectColor" slot="reference" @click="arrowDown=!arrowDown">
              <div class="currentColor" :class="{noColor:!editData.colorNo}" :style="{background:editData.colorNo}"></div>
              <i :class="[arrowDown?'el-icon-arrow-down':'el-icon-arrow-up']"></i>
            </div>
          </el-popover>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSure">确 定</el-button>
      </span>
    </el-dialog>
     <resultDialog 
      name="客户" 
      :resultVisible.sync="resultVisible" 
      :type="resultType" 
      :fileName="fileName"
      :resultMsg="resultMsg"
      :importResult="importResult"
      @reUpload="reUpload"
     />
  </div>
</template>

<script>
const pagination = () => import('@/components/pagination')
const upload = () => import('@/components/upload/upload')
const resultDialog = () => import('@/components/upload/resultDialog')
import {getCustomer,delCustomer,editCustomer,importCustomer,addCustomer,delCustomerBatch} from '@/api/data.js'
import { isComfirm } from '@/utils/data.js'
export default {
  name: "customerManagement",
  components:{
    pagination,
    upload,
    resultDialog
  },
  data() {
    return {
      tableData:[],
      total:0,
      page:1,
      size:10,
      searchValue:'',
      dialogVisible:false,
      resultVisible:false,
      resultType:true,
      resultMsg:'',
      fileName:'',
      editData:{
        "customerName": "",
        "customerCode": "",
        "priority": "",
        "colorNo": "",
        "id":""
      },
      rules:Object.freeze({
        customerName:[
          { required: true, message: '请输入客户名称', trigger: 'change' },
        ],
        customerCode:[
          {required: true, message: '请输入客户编号', trigger: 'change'}
        ],
        priority:[
          {required: true, message: '请输入优先级', trigger: 'change'}
        ],
      }),
      multipleSelection:[],
      type:'create',
      importResult:true,
      priorityList:[1,2,3,4,5,6,7,8,9,10],
      colorList:Object.freeze([
        '#87CB2D','#4DFFE1','#1928FF','#884DFF','#6E00FF','#2DCB2D','#97FFED','#3668F3','#7530FF','#9C51FF',
        '#03AA56','#85D2EF','#0A7EF2','#5500FF','#B37AFF','#4DFF59','#11B3FB','#0071A4','#9100FF','#CBA4FF',
        '#2DEB79','#0077FF','#6D9EFF','#B84DFF','#DABEFF','#7EFF87','#509CFF','#4469D1','#884DFF','#F1C1FF'
      ]),
      arrowDown:true
    };
  },
  watch:{
    size(){
      this.handleSearch(1)
    },
    dialogVisible(val){
      if(!val){
        Object.assign(this.editData, this.$options.data().editData);
      }
    }
  },
  activated(){
    this.handleSearch()
  },
  methods:{
    changeColor(item){
      this.$set(this.editData,'colorNo',item)
    },
    handleSelectionChange(val){
      this.multipleSelection = val.map(row => row.id);
    },
    //查询客户列表
    handleSearch(page){
      page ? this.page = page : this.page = 1;
      let params = {
        page:this.page,
        size:this.size,
        searchValue:this.searchValue
      }
      getCustomer(params).then(res=>{
        if(res.code === 0){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message.error(res.msg || '接口出错')
        }
      }).catch(()=>{
        this.$message.error('接口出错')
      })
    },
    handleClose(){
      this.$refs.ruleForm.clearValidate();
      this.dialogVisible = false;
    },
    handleCreate(){
      this.dialogVisible = true;
      this.type = 'create';
      this.$refs.ruleForm.clearValidate();
    },
    handleEdit(row){
      this.dialogVisible = true;
      this.type = 'edit';
      this.editData = {...row}
    },
    
    //新增编辑客户
    handleSure(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.edit()
        } else {
          this.$message.error('请补充完整信息')
          return false;
        }
      });
    },
    edit(){
      let Api = this.editData.id? editCustomer : addCustomer;
      Api(this.editData).then(res=>{
        if(res.code === 0){
          this.$message.success('操作成功')
          this.dialogVisible = false
          this.handleSearch()
        }else{
          if(res.msg == '已有相同颜色存在，请重新选择'){
            this.importResult = false
            this.resultVisible = true
          }else{
            this.$message.error(res.msg || '接口失败')
          }
        }
      })
    },
    comfirm(row){
      if(!row && !this.multipleSelection.length){
        this.$message.warning('请选择要删除的数据')
        return
      }
      isComfirm(row,row ? this.del : this.batchdel)
    },
    //删除客户
    del(row){
      delCustomer(row.id).then(res=>{
        if(res.code === 0){
          this.$message.success('删除成功')
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    batchdel(){
      delCustomerBatch(this.multipleSelection).then(res=>{
        if(res.code === 0){
          this.$message.success('删除成功')
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    uploadFile(file){
      this.importResult = true;
      let formData = new FormData();
      formData.append('file',file.raw);
      this.fileName = file.name
      importCustomer(formData).then(res=>{
        this.resultMsg = res.msg
        if(res.code == 0){
          this.resultVisible = true
          this.resultType = true
          this.handleSearch(1)
        }else{
          this.resultVisible = true
          this.resultType = false
        }
      }).catch((error)=>{
        this.resultMsg = error.response?.data?.msg
        this.resultVisible = true
        this.resultType = false
      })
    },
    reUpload(){
      this.$refs.upload.reUpload()
    }
  }
};
</script>

<style lang="scss">
.customerManagement{
  padding: 18px;
  color: #E8E8E8;
  text-align: left;
  .content{
    position: relative;
    background: #232323;
    min-height: calc(100vh - 280px);
    text-align: left;
    padding: 25px 35px 80px 35px;
    .tableHeader{
      background: #2C2C2C;
      color: #B9B9B9;
    }
    .title{
      font-size: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
    img{
      width: 22px;
      height: 22px;
    }
    .tool{
      width: 350px;
      display: flex;
      align-items: center;
      img{
        margin: 0 15px;
        cursor: pointer;
        width: 22px;
        height: 22px;
      }
    }
    .colorBox{
      height:32px;
      width:32px;
      border-radius: 6px;
    }
  }
  .editBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;
    .el-form-item{
      margin-bottom:5px!important;
      width: 45%;
    }
    .el-form-item__content{
      width: 100%;
    }
    .el-form-item__label{
      font-size: 14px;
      line-height: 26px!important;
    }
    .el-select,.el-input,.el-color-picker{
      width: 100%!important;
    }
    .el-form-item.is-error .el-input__inner{
      border-color: #F56C6C!important;
    }
    .item{
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      // width: 45%;
      align-items: flex-start;
      .el-input{
        width: 100%;
       }
    }
  }
}
</style>
<style scoped lang="scss">
.colorContent{
  display: grid;
  grid-template-columns: repeat(5,1fr);
  grid-gap: 10px;
  .item{
    width: 36px;
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
  }
}
.selectColor{
  height: 30px;
  background: #232323;
  border-radius: 6px;
  border: 1px solid #4F4F4F;
  padding: 5px;
  cursor: pointer;
  position: relative;
  // &:after{
  //   position: absolute;
  //   content: "\e6e1";
  //   right: 10px;
  //   top: 50%;
  //   transform: translate(-50%,-50%);
  // }
  .el-icon-arrow-down,.el-icon-arrow-up{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .currentColor{
    width: 26px;
    height: 26px;
    background: transparent;
    border-radius: 4px;
  }
  .noColor{
    border: 1px solid #4F4F4F;
    position: relative;
    &:after{
      position: absolute;
      content: 'X';
      display: block;
      color: #4F4F4F;
      top: 50%;
      right: 50%;
      transform: translate(50%,-50%);
    }
  }
}
</style>
